import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Wrapper from '../../utils/wrapper'


const Associates = () => {
    //TODO: Fetch data from CMS
    
    const associates = useStaticQuery(
        graphql `
        query {
            allWpTeamMember(filter: {categories: {nodes: {elemMatch: {name: {ne: "Partner"}}}}}) {
              edges {
                node {
                  title
                }
              }
            }
          }
           
        `
    )
    
    const allAssociates = associates.allWpTeamMember.edges.map(
        (associate, key) => <li key={key}>{associate.node.title}</li>
        )

    return(
        <Wrapper 
            centerContent={
                <>
                    <h2 className="text-4xl">Associates</h2>
                    <ul className="mt-2 ">
                        {allAssociates}
                    </ul>
                </>
            }
        />
    )
}

export default Associates