import React from "react"

const Wrapper = ({
  leftContent = "",
  centerContent,
  rightFull = false,
  marginTopBottom = true,
}) => {
  return (
    <section
      className={`flex lg:px-16 px-8 ${
        marginTopBottom ? "my-20" : ""
      }   lg:flex-row flex-col`}
    >
      <header className="lg:w-3/12 lg:pr-14 xl:pr-20">{leftContent}</header>

      <section className={rightFull ? `lg:w-8/12` : `lg:w-6/12`}>
        {centerContent}
      </section>
      {!rightFull && <section className="lg:w-3/12"></section>}
    </section>
  )
}

export default Wrapper
