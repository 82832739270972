import React from "react"

import Partner from "./partner"

import { graphql, useStaticQuery } from "gatsby"

//Todo: Pull data from CMS
const Partners = () => {
  const partners = useStaticQuery(graphql`
    {
      allWpTeamMember(
        sort: { fields: id, order: DESC }
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Partner" } } } }
        }
      ) {
        edges {
          node {
            title
            content
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const allPartners = partners.allWpTeamMember.edges.map(partner => (
    <Partner
      // key={key}
      fullname={partner.node.title}
      image={
        partner.node.featuredImage.node.localFile.childImageSharp
          .gatsbyImageData
      }
      role={partner.node.content}
    />
  ))

  return (
    <section className="grid md:grid-cols-3 lg:grid-cols-4 grid-cols-2 md:px-20 px-8 sm:gap-6 gap-4 pb-20">
      {allPartners}
    </section>
  )
}

export default Partners
