import React from "react"
import Wrapper from "./wrapper"
import ViewProjectArrow from "../icons/view-project-arrow"

import { useContactFormContext } from "../../contexts/contact-form-context"

const Magic = () => {
  const { openContactForm } = useContactFormContext()
  return (
    <div
      className="bg-gray-50 md:py-24 py-16  cursor-pointer magic"
      onClick={openContactForm}
      onKeyDown={openContactForm}
      role="button"
      tabIndex="0"
    >
      <Wrapper
        marginTopBottom={false}
        centerContent={
          <>
            <h2 className="sm:text-5xl">Ready to work with us?</h2>
            <div className="flex items-center">
              <p className="sm:text-2xl mb-0 text-xl font-normal pr-2">
              Start a project
              </p>
              <ViewProjectArrow />
            </div>
          </>
        }
      />
    </div>
  )
}

export default Magic
