import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Partner = ({ image, fullname, role }) => {
  const partner_role = role.replace(/<p>|<\/p>/g, "")
  return (
    <div>
      <GatsbyImage image={image} alt={fullname} />
      <h3 className="md:text-xl lg:text-2xl text-base mt-4">{fullname}</h3>
      <p className="-mt-2 md:text-base text-sm leading-normal">
        {partner_role}
      </p>
    </div>
  )
}

export default Partner
