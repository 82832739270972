import React from "react"
import Wrapper from "./wrapper"

const BodyTitleContent = ({ heading = "", content, children, stacked }) => {
  const leftContent = <h2 className="md:pt-2">{heading}</h2>
  const centerContent = (
    <>
      {stacked && leftContent}
      {content || children}
    </>
  )
  return (
    <Wrapper
      leftContent={!stacked && leftContent}
      centerContent={centerContent}
    />
  )
}

export default BodyTitleContent
