import React from "react"
import Fade from "react-reveal/Fade"

const PageHeader = ({ text, home = false, dark = true }) => {
  return (
    <Fade>
      <header className="sm:pr-16 pr-8 ">
        <section
          className={
            (home ? " md:pt-80  pt-72 " : " md:pt-64 pt-48") +
            " " +
            (dark ? `bg-black text-white` : `bg-white text-black`) +
            `  lg:pl-16 pl-8 font-semibold md:pb-20 pb-8 leading-[1] lg:text-[6rem] md:text-7xl sm:text-6xl text-[38px]`
          }
        >
          <h1
            className={`sm:max-w-[85%] ${
              home ? "max-w-[100%]" : "max-w-[90%]"
            } `}
          >
            {text}
          </h1>
        </section>
      </header>
    </Fade>
  )
}

export default PageHeader
