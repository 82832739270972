import React from "react"
import Associates from "../components/about/people/associates"
import Partners from "../components/about/people/partners"

import Layout from "../components/layout"
import Magic from "../components/utils/magic"
import PageHeader from "../components/utils/page-header"
import BodyTitleContent from "../components/utils/title-content"

const AboutUs = () => {
  const seo = {
    title: "About Us",
    description: `We are inspired by our collective purpose to partner with visionary
    organizations to build compelling and lasting brands with design,
    and to fulfil this, we go all the way and walk the talk.`,
  }
  return (
    <Layout seo={seo}>
      <PageHeader
        text={
          <>
           We partner with good businesses to build
            <br />
           great brands
          </>
        }
      />

      <BodyTitleContent
        content={
          <>
            <p>
            When we started in 2015, the desire was to do incredible design work for ambitious companies. And for the last seven years, we have successfully traveled that journey working with some of Africa’s influential companies and brands.
            </p>

            <p>
            Today, FourthCanvas has grown into a strategy-led brand design agency that partners with honest business leaders who want to change the world with their ideas and build a compelling brand while at it.
            </p>
          </>
        }
      />
      <BodyTitleContent
        heading=""
        content={
          <>
            <p>
            Our team — made up of an open, warm, and collaborative culture — is a talented mix of explorers, thinkers, artists, and builders who make up an assembly of Africa’s finest brand leaders and developers.{" "}
            </p>
          </>
        }
      />

      <Partners />
      <Associates />
      <Magic />
    </Layout>
  )
}

export default AboutUs
